import { useState, useEffect, useCallback, SetStateAction } from 'react'
import Button from '@material-ui/core/Button'
import axios from '../../../api/axiosV2'
import classNames from 'classnames'
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table'
import 'react-bootstrap-table/css/react-bootstrap-table.css'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Pagination from 'react-js-pagination'
import DetailDialog from './detailDialog'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import scss from '../ads.module.scss'
import { AdsSubject } from 'types/Ads'
import { getCostList } from 'api/ads'
import { alertMessage } from 'common/utils'
import { debounce } from 'utils'

const AdsCostList = () => {
	const options = { defaultSortName: 'seq', noDataText: '데이터 없음' }
	const [dataList, setDataList] = useState<AdsSubject[]>([])
	const [listTotal, setListTotal] = useState(0)
	const [detailData, setDetailData] = useState({})
	const [dialogType, setDialogType] = useState('등록')
	const [showDetail, setShowDetail] = useState(false)

	// params
	const [activePage, setActivePage] = useState(1)
	const [sizePerPage, setSizePerPage] = useState(10)
	const [searchValue, setSearchValue] = useState('')
	const [defaultOrder, setDefaultOrder] = useState('seq')
	const [align, setAlign] = useState('desc')

	const loadData = useCallback(async () => {
		{
			const params = {
				search: searchValue,
				page: activePage,
				row: sizePerPage,
				orderBy: defaultOrder,
				align: align
			}
			try {
				const res = await getCostList('', params)
				if (res.status.code === 200) {
					setDataList(res.data.list)
					setListTotal(res.data.totalCount)
				} else {
					console.error(res)
				}
			} catch (e) {
				console.error(e)
			}
		}
	}, [activePage, sizePerPage, defaultOrder, align, searchValue])

	const changeSizePerPage = (n: number) => {
		setActivePage(1)
		setSizePerPage(n)
	}

	const handlePageChange = (pageNumber: number) => {
		setActivePage(pageNumber)
	}

	useEffect(() => {
		loadData()
	}, [activePage, sizePerPage, defaultOrder, align])

	// Dialog type 등록/수정 판단 후 오픈
	const onDialog = async (type: string) => {
		setDialogType(type)
		setTimeout(() => {
			setShowDetail(true)
		}, 100)
	}

	//Table 상세버튼
	const cellButton = (row) => {
		return (
			<Button
				variant="outlined"
				color="default"
				className={'dark:text-moaWhite dark:border-moaWhite'}
				onClick={async () => {
					try {
						setDetailData(row)
						setTimeout(() => {
							onDialog('수정')
						}, 100)
					} catch (e) {
						alertMessage({ title: '경고', type: 'danger', message: `에러가 발생하였습니다.\n관리자에게 문의해주세요. ${e}` })
					}
				}}
			>
				수정
			</Button>
		)
	}

	const dataSort = async (dataField) => {
		if (defaultOrder !== dataField) {
			setDefaultOrder(dataField)
			setAlign('desc')
		} else {
			setDefaultOrder(dataField)
			align === 'desc' ? setAlign('asc') : setAlign('desc')
		}

		loadData()
	}

	const orderCheck = (value) => {
		if (value === defaultOrder) {
			return (
				<div onClick={() => dataSort(value)} style={{ cursor: 'pointer' }}>
					{value === 'seq' ? 'No' : value === 'location' ? '광고위치' : value === 'type' ? '과금방식' : value === 'state' ? '광고상태' : value}
					<span className={classNames('order', align === 'asc' ? 'dropup' : '')}>
						<span className="caret" style={{ margin: '10px 5px' }}></span>
					</span>
				</div>
			)
		} else {
			return (
				<div onClick={() => dataSort(value)} style={{ cursor: 'pointer' }}>
					{value === 'seq' ? 'No' : value === 'location' ? '광고위치' : value === 'type' ? '과금방식' : value === 'state' ? '광고상태' : value}
					<span className="order">
						<span className="dropdown">
							<span className="caret" style={{ margin: '10px 0px 10px 5px', color: 'rgb(204, 204, 204)' }}></span>
						</span>
						<span className="dropup">
							<span className="caret" style={{ margin: '10px 0px', color: 'rgb(204, 204, 204)' }}></span>
						</span>
					</span>
				</div>
			)
		}
	}

	const onKeyDown = (event) => {
		debounce(() => {
			if (event.key === 'Enter') {
				event.preventDefault()
				event.stopPropagation()
				loadData()
			}
		}, 300)
	}

	useEffect(() => {
		setActivePage(1)
	}, [listTotal])

	return (
		<div
			style={{
				padding: '20px',
				paddingTop: '5vh',
				minWidth: isMobile ? '100%' : 'none',
				maxWidth: isMobile ? '600px' : '1400px',
				marginLeft: 'auto',
				marginRight: 'auto',
				boxSizing: 'border-box'
			}}
		>
			<ReactNotifications />
			<div>
				<div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: '20px' }}>
					<InsertButton
						btnText="신규등록"
						onClick={() => {
							setDetailData({})
							onDialog('등록')
						}}
					/>
					<div style={{ padding: 0 }} className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
						<div className="form-group-sm react-bs-table-search-form input-group input-group-sm">
							<input
								className="form-control "
								type="text"
								placeholder={'광고주 검색 '}
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
								onKeyDown={(event) => onKeyDown(event)}
								style={{ zIndex: '0' }}
							></input>
							<span className="input-group-btn">
								<button className="btn btn-default" onClick={() => loadData()}>
									검색
								</button>
							</span>
						</div>
					</div>
				</div>

				<BootstrapTable
					data={dataList}
					options={options}
					hover
					trClassName={classNames(scss['ads-table'])}
					condensed={true}
					keyField="uniqueId"
				>
					<TableHeaderColumn dataField="uniqueId" hidden>
						ID
					</TableHeaderColumn>
					<TableHeaderColumn
						width="35px"
						dataAlign="center"
						dataFormat={(cell, row, enumObject, index) => align == 'desc' ?  <span>{listTotal - index - (activePage-1) * sizePerPage}</span> :  <span>{index + 1 + (activePage - 1) * sizePerPage}</span>}
					>
					{orderCheck('seq')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="manager"
						width="70px"
						dataAlign="center"
						dataFormat={(cell) => <span>{cell?.advertiser || ''}</span>}
					>
						광고주
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="campaign"
						width="120px"
						dataAlign="center"
						dataFormat={(cell) => <span>{cell?.title || ''}</span>}
					>
						캠페인명
					</TableHeaderColumn>

					<TableHeaderColumn dataField="nickName" width="60px" dataAlign="center">
						과금별칭
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="type"
						width="55px"
						dataAlign="center"
						dataFormat={(cell) => <span>{cell === 'fixedCost' ? 'CPP' : cell.toUpperCase()}</span>}
					>
						{orderCheck('type')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="costValue"
						width="60px"
						dataAlign="center"
						dataFormat={(cell) => <span>{cell ? `${Number(cell).toLocaleString()}원` : '-'}</span>}
					>
						단가
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="totalCost"
						width="65px"
						dataFormat={(cell) => <span>{cell ? `${Number(cell).toLocaleString()}원` : '-'}</span>}
					>
						총 예산
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="dailyLimit"
						width="65px"
						dataAlign="center"
						dataFormat={(cell) => <span>{cell ? `${Number(cell).toLocaleString()}원` : '-'}</span>}
					>
						일일 예산
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="button"
						width="50px"
						dataAlign="left"
						dataFormat={(_, row) => cellButton(row)}
					></TableHeaderColumn>
				</BootstrapTable>
				<div className="btn-group" style={{ width: '100%' }}>
					{[10, 25, 50].map((n, idx) => {
						const isActive = n === sizePerPage ? 'active' : 'none'
						return (
							<button
								key={idx}
								type="button"
								style={{ margin: isMobile ? '20px 0 0' : '20px 0' }}
								className={`btn ${isActive}`}
								onClick={() => changeSizePerPage(n)}
							>
								{n}
							</button>
						)
					})}
					<div style={{ float: 'right', width: isMobile ? '100%' : 'none', textAlign: isMobile ? 'right' : 'left' }}>
						<Pagination
							activePage={activePage}
							itemsCountPerPage={sizePerPage}
							totalItemsCount={listTotal}
							pageRangeDisplayed={5}
							onChange={(event) => handlePageChange(event)}
						/>
					</div>
				</div>
			</div>
			{showDetail && (
				<DetailDialog dialogType={dialogType} showDetail={showDetail} setShowDetail={setShowDetail} detailData={detailData} />
			)}
		</div>
	)
}

export default AdsCostList
