import React, { useState } from 'react'
import CustomDialog from '../../../wrapper/CustomDialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { alertMessage } from 'common/utils'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { debounce } from 'utils'
import { deleteCampaign, getManagerList, registCampaign, updateCampaign } from 'api/ads'

const DetailDialog = ({ dialogType, showDetail, setShowDetail, detailData }) => {
	const [managerInfo, setManagerInfo] = useState([])
	const [showManagerList, setShowManagerList] = useState(false)
	const { control, handleSubmit, setValue } = useForm({
		defaultValues: {
			...detailData,
			manager: {
				advertiser: detailData?.manager?.advertiser || '',
				name: detailData?.manager?.name || '',
				seq: detailData?.manager?.seq || null
			}
		}
	})

	const handleDelete = async () => {
		const result = window.confirm('해당 캠페인을 삭제하겠습니까?')
		if (result) {
			try {
				const res = await deleteCampaign(detailData.seq)
				if (res.status.code === 200) {
					alertMessage({ title: '알림', type: 'success', message: '삭제가 완료되었습니다.' })
					setShowDetail(false)
					setTimeout(() => {
						window.location.reload()
					}, 100)
				} else {
					alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + res })
				}
			} catch (error) {
				console.error(error)
				alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + error.message })
				setShowDetail(false)
			}
		}
	}

	const handleSend = async (data) => {
		const params = {
			title: data.title,
			totalCost: parseInt(data.totalCost),
			createdAt: moment.utc(data.createdAt).toISOString() || moment().utc.startOf('hour').toISOString(),
			managerSeq: data.manager.seq
		}
		try {
			const res = data?.seq ? await updateCampaign(data.seq, params) : await registCampaign(params)
			if (res?.status?.code === 200 || res?.status?.code === 201) {
				alertMessage({ title: '성공', type: 'success', message: '등록이 완료되었습니다.' })
				setShowDetail(false)
				setTimeout(() => {
					window.location.reload()
				}, 100)
			} else {
				alertMessage({
					title: '경고',
					type: 'danger',
					message: `에러가 발생하였습니다. 다시 시도해주세요.\n
									${res?.response?.data?.message[0] || res?.response?.data?.message}`
				})
			}
		} catch (error) {
			console.error(error)
			alertMessage({
				title: '경고',
				type: 'danger',
				message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + error
			})
		}
	}

	const handleCompanyChange = async (value, onChange) => {
		onChange({ ...value, advertiser: value })
		setShowManagerList(true)
		debounce(async () => {
			const res = await getManagerList({ search: value })
			setManagerInfo(res.data)
		}, 200)
	}

	const clickManager = async (manager, onChange) => {
		onChange(manager)
		setValue('manager', manager) // 전체 manager 객체 업데이트
		setTimeout(() => {
			setShowManagerList(false)
		}, 200)
	}

	const renderPage = () => (
		<div className="row" style={{ width: '80vw', margin: '0px', maxWidth: '400px', minWidth: '240px' }}>
			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">광고주</span>
				<Controller
					name="manager"
					control={control}
					render={({ field: { onChange, value } }) => (
						<>
							<TextField
								placeholder="회사명 입력"
								type="text"
								value={value?.advertiser || ''}
								onChange={(e) => handleCompanyChange(e.target.value, onChange)}
								style={{ width: '70%', minWidth: '160px' }}
								inputProps={{
									style: { fontSize: '15px' }
								}}
							/>
							{showManagerList && (
								<div className="bg-white max-h-[35%] w-[58%] min-w-[160px] shadow-lg border border-black rounded-md z-10 absolute top-[37%] left-[33.5%] overflow-scroll">
									{managerInfo.length > 0 ? (
										<>
											{managerInfo.map((manager) => {
												return (
													<div
														key={manager.seq}
														className="p-2 hover:bg-gray-100 hover:cursor-pointer"
														onClick={() => clickManager(manager, onChange)}
													>
														{manager.advertiser} ({manager.name})
													</div>
												)
											})}{' '}
										</>
									) : (
										<div className="p-2 hover:bg-gray-100 hover:cursor-pointer">검색결과 없음</div>
									)}
								</div>
							)}
						</>
					)}
				/>
			</div>

			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">캠페인명</span>
				<Controller
					name="title"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							type="text"
							value={value || ''}
							onChange={onChange}
							style={{ width: '70%', minWidth: '160px' }}
							inputProps={{
								style: { fontSize: '15px' }
							}}
						/>
					)}
				/>
			</div>
			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">계약일</span>
				<Controller
					defaultValue={moment().format('YYYY-MM-DD')}
					name="createdAt"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							type="date"
							value={moment(value).format('YYYY-MM-DD')}
							onChange={(e) => {
								onChange(e.target.value)
								console.log(e.target.value)
							}}
							style={{ width: '70%', minWidth: '160px' }}
							inputProps={{
								style: { fontSize: '15px' }
							}}
						/>
					)}
				/>
			</div>

			<div className="flex w-full justify-between items-center mb-4">
				<span className="text-base text-[15px]">한도 예산</span>
				<Controller
					name="totalCost"
					control={control}
					render={({ field: { onChange, value } }) => {
						return (
							<TextField
								type="number"
								value={value || ''}
								onChange={onChange}
								style={{ width: '70%', minWidth: '160px' }}
								inputProps={{
									style: { fontSize: '15px' }
								}}
							/>
						)
					}}
				/>
			</div>
		</div>
	)

	return (
		<div>
			<CustomDialog onOpen={showDetail} onClose={showDetail} title={`캠페인 ${dialogType}`} aria-labelledby="event-dialog">
				<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>{renderPage()}</DialogContent>
				<DialogActions>
					<div style={{ display: 'flex', width: '100%', justifyContent: dialogType == '수정' ? 'space-between' : 'flex-end' }}>
						{dialogType == '수정' && (
							<Button variant="outlined" onClick={handleDelete} color="secondary">
								삭제
							</Button>
						)}
						<div>
							<Button variant="outlined" onClick={() => setShowDetail(false)} color="default" style={{ marginRight: '10px' }}>
								취소
							</Button>
							<Button variant="outlined" onClick={handleSubmit(handleSend)} color="primary" autoFocus>
								등록
							</Button>
						</div>
					</div>
				</DialogActions>
			</CustomDialog>
		</div>
	)
}

export default DetailDialog
