import axios from 'axios'

const axiosV2 = axios.create({
	baseURL: process.env.REACT_APP_STUDY_MOA_API_URL_V2,
	headers: {
		'Access-Control-Allow-Origin': 'https://stgads.studymoa.me, https://stg.studymoa.me, http://localhost:3000',
		Authorization: `Bearer ${localStorage.getItem('access_token')}` || '',
		'Content-type': 'application/json'
	}
})

axiosV2.interceptors.response.use(
	function (res: any) {
		const { data } = res
		if (data.result == 'fail' && data.message == '사용할 수 없는 계정입니다') {
			localStorage.removeItem('access_token')
			window.location.href = '/'
		}
		return res
	},
	function (err) {
		const { response = {} } = err
		const { data = {} } = response
		if (data.result == 'fail' && data.message == '사용할 수 없는 계정입니다') {
			localStorage.removeItem('access_token')
			console.log('call')
			window.location.href = '/'
		}
		return err
	}
)

export default axiosV2
