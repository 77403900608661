import React, { useState, useEffect } from 'react'
import CustomDialog from '../../../wrapper/CustomDialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { alertMessage } from 'common/utils'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Controller, useForm } from 'react-hook-form'
import { isMobile } from 'react-device-detect'
import { deleteManager, updateManager, registManager } from 'api/ads'

const DetailDialog = ({ dialogType, showDetail, setShowDetail, detailData }) => {
	const { control, handleSubmit } = useForm({ defaultValues: { ...detailData } })

	const handleDelete = async () => {
		const result = window.confirm('해당 광고주를 삭제하겠습니까?')
		if (result) {
			try {
				const res = await deleteManager(detailData.seq)
				if (res.status.code === 200) {
					alertMessage({ title: '성공', type: 'success', message: '삭제가 완료되었습니다.' })
					setShowDetail(false)
					setTimeout(() => {
						window.location.reload()
					}, 100)
				} else {
					alertMessage({
						title: '경고',
						type: 'danger',
						message: `에러가 발생하였습니다. 다시 시도해주세요.\n
					${res?.response?.data?.message[0] || res?.response?.data?.message}`
					})
				}
			} catch (error) {
				console.error(error)
				alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + error.message })
				setShowDetail(false)
			}
		}
	}

	const handleSend = async (data) => {
		try {
			const res = data?.seq ? await updateManager(data) : await registManager(data)

			if (res.status.code == 200 || res.status.code == 201) {
				alertMessage({ title: '성공', type: 'success', message: '등록이 완료되었습니다.' })
				setShowDetail(false)
				setTimeout(() => {
					window.location.reload()
				}, 100)
			} else {
				alertMessage({
					title: '경고',
					type: 'danger',
					message: `에러가 발생하였습니다. 다시 시도해주세요.\n
				${res?.response?.data?.message[0] || res?.response?.data?.message}`
				})
			}
		} catch (error) {
			console.error(error)
			alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + error.message })
			setShowDetail(false)
		}
	}

	// mobile
	const mobilePage = () => (
		<div className="row" style={{ width: '255px', margin: '0px' }}>
			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">광고주</span>
				<Controller
					name="company"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							placeholder="회사명 입력"
							type="text"
							value={value}
							onChange={onChange}
							inputProps={{
								style: {
									width: '150px',
									fontSize: '15px'
								}
							}}
						/>
					)}
				/>
			</div>

			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">연락처</span>
				<Controller
					name="phone"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							placeholder="- 없이 숫자만"
							type="text"
							value={value}
							onChange={onChange}
							inputProps={{
								style: {
									width: '150px',
									fontSize: '15px'
								}
							}}
						/>
					)}
				/>
			</div>

			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">담당자 명</span>
				<Controller
					name="name"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							type="text"
							value={value}
							onChange={onChange}
							inputProps={{
								style: {
									width: '150px',
									fontSize: '15px'
								}
							}}
						/>
					)}
				/>
			</div>

			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">담당자 이메일</span>
				<Controller
					name="email"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							type="text"
							value={value}
							onChange={onChange}
							inputProps={{
								style: {
									width: '150px',
									fontSize: '15px'
								}
							}}
						/>
					)}
				/>
			</div>

			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">아이디</span>
				<Controller
					name="id"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							placeholder="생성할 ID 입력"
							type="text"
							value={value}
							onChange={onChange}
							inputProps={{
								style: {
									width: '150px',
									fontSize: '15px'
								}
							}}
						/>
					)}
				/>
			</div>

			<div className="flex w-full justify-between items-center mb-1">
				<span className="text-base text-[15px]">비밀번호</span>
				<Controller
					name="pin"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							placeholder="생성할 비밀번호 입력"
							type="text"
							value={value}
							onChange={onChange}
							inputProps={{
								maxLength: 30,
								style: {
									width: '150px',
									fontSize: '15px'
								}
							}}
						/>
					)}
				/>
			</div>

			<div className="flex w-full justify-between mb-2">
				<span className="text-base text-[15px]">특이사항</span>
				<Controller
					name="memo"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							placeholder="메모 입력"
							type="text"
							multiline
							rows={3}
							value={value}
							onChange={onChange}
							inputProps={{
								style: {
									width: '150px',
									fontSize: '14px'
								}
							}}
						/>
					)}
				/>
			</div>
		</div>
	)

	// PC
	const pcPage = () => (
		<div className="row" style={{ width: '550px' }}>
			<div className="flex w-full justify-between">
				<div className="flex w-[258px] justify-end items-center">
					<span className="mr-3 text-right text-[15px]">광고주</span>
					<Controller
						name="advertiser"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="회사명 입력"
								type="text"
								value={value}
								onChange={onChange}
								inputProps={{
									style: {
										width: '160px',
										fontSize: '14px'
									}
								}}
							/>
						)}
					/>
				</div>

				<div className="flex justify-end items-center">
					<span className="mr-3 text-[15px]">연락처</span>
					<Controller
						name="phone"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="- 없이 숫자만"
								type="text"
								value={value}
								onChange={onChange}
								inputProps={{
									style: {
										width: '160px',
										fontSize: '14px'
									}
								}}
							/>
						)}
					/>
				</div>
			</div>
			<div className="flex w-full justify-between">
				<div className="flex w-[258px] justify-end items-center">
					<span className="mr-3 text-[15px]">담당자 명</span>
					<Controller
						name="name"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								type="text"
								value={value}
								onChange={onChange}
								inputProps={{
									style: {
										width: '160px',
										fontSize: '14px'
									}
								}}
							/>
						)}
					/>
				</div>

				<div className="flex justify-end items-center">
					<div className="mr-3 text-[15px]">담당자 이메일</div>
					<Controller
						name="email"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								type="text"
								value={value}
								onChange={onChange}
								inputProps={{
									style: {
										width: '160px',
										fontSize: '14px'
									}
								}}
							/>
						)}
					/>
				</div>
			</div>
			<div className="flex w-full justify-between mb-1">
				<div className="flex w-[258px] justify-end items-center">
					<span className="mr-3 text-[15px]">아이디</span>
					<Controller
						name="id"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="생성할 ID 입력"
								type="text"
								value={value}
								onChange={onChange}
								inputProps={{
									style: {
										width: '160px',
										fontSize: '14px'
									}
								}}
							/>
						)}
					/>
				</div>

				<div className="flex justify-end items-center">
					<span className="mr-3 text-right text-[15px]">비밀번호</span>
					<Controller
						name="pin"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="생성할 비밀번호 입력"
								type="text"
								value={value}
								onChange={onChange}
								inputProps={{
									maxLength: 30,
									style: {
										width: '160px',
										fontSize: '14px'
									}
								}}
							/>
						)}
					/>
				</div>
			</div>

			<div className="flex justify-end mb-4">
				<span className="mr-3 text-right text-[15px]">특이사항</span>
				<Controller
					name="memo"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							style={{ width: '82.2%' }}
							placeholder="메모 입력"
							type="text"
							multiline
							rows={3}
							value={value}
							onChange={onChange}
							inputProps={{
								style: {
									width: '100%',
									fontSize: '14px'
								}
							}}
						/>
					)}
				/>
			</div>
		</div>
	)

	const renderPage = () => (isMobile ? mobilePage() : pcPage())

	return (
		<div>
			<CustomDialog
				onOpen={showDetail}
				onClose={showDetail}
				title={`광고주 ${dialogType}`}
				maxWidth="md"
				minWidth="200px"
				aria-labelledby="event-dialog"
			>
				<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>{renderPage()}</DialogContent>
				<DialogActions>
					<div style={{ display: 'flex', width: '100%', justifyContent: dialogType == '수정' ? 'space-between' : 'flex-end' }}>
						{dialogType == '수정' && (
							<Button variant="outlined" onClick={handleDelete} color="secondary">
								삭제
							</Button>
						)}
						<div>
							<Button variant="outlined" onClick={() => setShowDetail(false)} color="default" style={{ marginRight: '10px' }}>
								취소
							</Button>
							<Button variant="outlined" onClick={handleSubmit(handleSend)} color="primary" autoFocus>
								등록
							</Button>
						</div>
					</div>
				</DialogActions>
			</CustomDialog>
		</div>
	)
}

export default DetailDialog
