import { useState, useEffect } from 'react'
import { alertMessage } from 'common/utils'
import CustomDialog from '../../../wrapper/CustomDialog'
import { DialogActions, DialogContent, TextField, Select, MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Controller, useForm } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import { getManagerList, getCampaignList, registCost, updateCost, deleteCost } from 'api/ads'
import { debounce } from 'utils'

const DetailDialog = ({ dialogType, showDetail, setShowDetail, detailData }) => {
	const [managerInfo, setManagerInfo] = useState([])
	const [showManagerList, setShowManagerList] = useState(false)
	const [campaignInfo, setCampaignInfo] = useState([])

	const { control, handleSubmit, watch } = useForm({
		defaultValues: { ...detailData }
	})

	const watchAdType = watch('type')

	useEffect(() => {
		setCampaignInfo([detailData?.campaign])
	}, [])

	const handleSend = async (data) => {
		try {
			const params = {
				managerSeq: data.manager.seq,
				campaignSeq: data.campaign.seq,
				nickName: data.nickName,
				type: data.type,
				costValue: data.type == 'fixedCost' ? 0 : data.costValue,
				totalCost: data.totalCost,
				dailyLimit: data.type == 'fixedCost' ? 0 : data.dailyLimit
			}
			const res = data.seq ? await updateCost(data.seq, params) : await registCost(params)
			if (res?.status?.code === 200 || res?.status?.code === 201) {
				alertMessage({ title: '성공', type: 'success', message: '등록이 완료되었습니다.' })
				setShowDetail(false)
				setTimeout(() => {
					window.location.reload()
				}, 100)
			} else {
				alertMessage({
					title: '경고',
					type: 'danger',
					message: `에러가 발생하였습니다. 다시 시도해주세요.\n
				${res?.response?.data?.message[0] || res?.response?.data?.message}`
				})
			}
		} catch (e) {
			console.error(e)
			alertMessage({
				title: '경고',
				type: 'danger',
				message: e?.response?.data?.message || '에러가 발생하였습니다.\n관리자에게 문의해주세요.'
			})
		}
	}

	const handleDelete = async () => {
		const result = window.confirm('해당 단가를 삭제하겠습니까?')
		if (result) {
			try {
				const res = await deleteCost(detailData.seq)
				if (res.status.code === 200) {
					alertMessage({ title: '알림', type: 'success', message: '삭제가 완료되었습니다.' })
					setShowDetail(false)
					setTimeout(() => {
						window.location.reload()
					}, 100)
				} else {
					alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + res })
					setShowDetail(false)
				}
			} catch (error) {
				console.error(error)
				alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + error.message })
				setShowDetail(false)
			}
		}
	}

	const handleCompanyChange = async (value, onChange) => {
		onChange({ ...value, advertiser: value })
		debounce(async () => {
			const res = await getManagerList({ search: value })
			setManagerInfo(res.data)
		}, 200)
		setShowManagerList(true)
	}

	const clickManager = async (manager, onChange) => {
		onChange({ ...manager, advertiser: manager.advertiser })
		setManagerInfo(manager)
		setShowManagerList(false)

		setTimeout(async () => {
			loadCampaignInfo(manager.seq)
		}, 200)
	}

	const loadCampaignInfo = async (managerSeq) => {
		const res = await getCampaignList(managerSeq)
		setCampaignInfo(res.data)
	}

	const renderPage = () => (
		<div className="min-w-[500px]">
			<div className="flex flex-col">
				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-20 mr-3">광고주</span>
					<Controller
						name="manager"
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								<TextField
									placeholder="광고주 입력"
									type="text"
									value={value?.advertiser || ''}
									onChange={(e) => handleCompanyChange(e.target.value, onChange)}
									style={{ width: '78%', minWidth: '160px' }}
									inputProps={{
										style: { fontSize: '15px' }
									}}
								/>
								{showManagerList && (
									<div className="bg-white max-h-[35%] w-[73%] min-w-[160px] shadow-lg border border-black rounded-md z-10 absolute top-[24%] left-[20%] overflow-scroll">
										{managerInfo?.length > 0 ? (
											<>
												{managerInfo.map((manager) => {
													return (
														<div
															key={manager.seq}
															className="p-2 hover:bg-gray-100 hover:cursor-pointer"
															onClick={() => clickManager(manager, onChange)}
														>
															{manager.advertiser} ({manager.name})
														</div>
													)
												})}
											</>
										) : (
											<div className="p-2 hover:bg-gray-100 hover:cursor-pointer">검색결과 없음</div>
										)}
									</div>
								)}
							</>
						)}
					/>
				</div>

				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-20 mr-3">캠페인명</span>
					<Controller
						name="campaign"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								style={{ width: '78%', minWidth: '160px' }}
								value={value?.seq || '0'}
								onChange={(e) => {
									onChange({ ...value, seq: e.target.value })
								}}
							>
								<MenuItem value={'0'}>선택하세요</MenuItem>
								{campaignInfo?.length > 0 &&
									campaignInfo?.map(
										(data) =>
											data && (
												<MenuItem key={data?.seq} value={data?.seq}>
													{data?.title}
												</MenuItem>
											)
									)}
							</Select>
						)}
					/>
				</div>

				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-20 mr-3">과금별칭</span>
					<Controller
						name="nickName"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="과금 별칭 입력"
								type="text"
								value={value || ''}
								onChange={onChange}
								style={{ width: '78%', minWidth: '160px' }}
								inputProps={{
									style: { fontSize: '15px' }
								}}
							/>
						)}
					/>
				</div>

				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-20 mr-3">과금방식</span>
					<Controller
						name="type"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								style={{ width: '110px', minWidth: '110px', marginRight: '4px' }}
								value={value || '0'}
								onChange={onChange}
							>
								<MenuItem value={'0'}>선택하세요</MenuItem>
								<MenuItem value={'fixedCost'}>CPP</MenuItem>
								<MenuItem value={'cpc'}>CPC</MenuItem>
								<MenuItem value={'cpm'}>CPM</MenuItem>
							</Select>
						)}
					/>
				</div>

				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-20 mr-3">단가</span>
					<Controller
						name="costValue"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="단가 입력"
								type="number"
								value={value || ''}
								disabled={watchAdType === 'fixedCost'}
								onChange={onChange}
								style={{ width: '78%', minWidth: '160px' }}
								inputProps={{
									style: { fontSize: '15px' }
								}}
							/>
						)}
					/>
				</div>

				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-20 mr-3">한도 예산</span>
					<Controller
						name="totalCost"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="한도 예산 입력"
								type="text"
								value={value || ''}
								onChange={onChange}
								style={{ width: '78%', minWidth: '160px' }}
								inputProps={{
									style: { fontSize: '15px' }
								}}
							/>
						)}
					/>
				</div>

				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-20 mr-3">일일 예산</span>
					<Controller
						name="dailyLimit"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="일일 예산 입력"
								type="text"
								value={value || ''}
								disabled={watchAdType === 'fixedCost'}
								onChange={onChange}
								style={{ width: '78%', minWidth: '160px' }}
								inputProps={{
									style: { fontSize: '15px' }
								}}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	)
	return (
		<div>
			<CustomDialog onOpen={showDetail} onClose={showDetail} title={`단가 ${dialogType}`} aria-labelledby="event-dialog">
				<DialogContent style={{ padding: 0 }}>{renderPage()}</DialogContent>
				<DialogActions>
					<div style={{ display: 'flex', width: '100%', justifyContent: dialogType == '수정' ? 'space-between' : 'flex-end' }}>
						{dialogType == '수정' && (
							<Button variant="outlined" onClick={handleDelete} color="secondary">
								삭제
							</Button>
						)}
						<div>
							<Button variant="outlined" onClick={() => setShowDetail(false)} color="default" style={{ marginRight: '10px' }}>
								취소
							</Button>
							<Button variant="outlined" onClick={handleSubmit(handleSend)} color="primary" autoFocus>
								등록
							</Button>
						</div>
					</div>
				</DialogActions>
			</CustomDialog>
		</div>
	)
}

export default DetailDialog
