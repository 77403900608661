import { useEffect } from 'react'

import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
// Test
import asyncComponent from '../components/async.component'
import Classic from '../layouts/layout-classic/LayoutClassic'
import AdminNotice from 'containers/AdminNotice'
import AdminBoard from 'containers/AdminNotice/AdminBoard'
import CheckSeqRouter from './protectRoutes/CheckSeqRouter'
import CheckAdminRouter from './protectRoutes/CheckAdminRouter'
import NoLayout from '../layouts/layout-none/layout-none.component'
function Redirect() {
	const navigate = useNavigate()
	useEffect(() => {
		navigate('/')
	}, [])

	return null
}

// AUTHENTICATION ROUTES

import Login from 'containers/login/Login'
import EditInfo from 'containers/editinfo/Editinfo'
import Dashboard from 'containers/dashboard/index'
import StudyPlace from 'containers/studyplace/studyplace.component'
import UserList from 'containers/userlist/userlist.component'
import MemberList from 'containers/memberlist/memberlist.component'
import AllMemberList from 'containers/memberlist/allMemberList.component'
import Notice from 'containers/notice/notice'
import Message from 'containers/message/message.component'
import AutoNoti from 'containers/message/autonoti.component'
import SeatBoard from 'containers/seatboard/SeatBoard'
import SeatSetting from 'containers/seatboard/SeatSetting'
import SProdSetting from 'containers/seatboard/sprodsetting.component'
import StudyRoom from 'containers/studyroom/studyroom.component'
import RoomSetting from 'containers/studyroom/RoomSetting'
import LockerBoard from 'containers/locker/lockerboard.component'
import LockerSetting from 'containers/locker/lockersetting.component'
import LProdSetting from 'containers/locker/lprodsetting.component'
import Cash from 'containers/cash/cash.component'
import CashStats from 'containers/cash/CashStatsPlace'
import CashStatsPg from 'containers/cash/cashStatsPg.component'
import Sales from 'containers/sales/sales.component'
import Settlement from 'containers/settlement/Settlement'

// ERROR ROUTES
const AsyncError404 = asyncComponent(() => import('../containers/errors/404.component'))
const AsyncError500 = asyncComponent(() => import('../containers/errors/500.component'))

// const AsyncNotFound = asyncComponent(() => import('./containers/not-found/not-found.component'))

// CUSTOM PAGES ROUTES

import Statistics from 'containers/statistics/statistics'
import Analysis from 'containers/statistics/analysis'
import Timephased from 'containers/statistics/timephased'
import Products from 'containers/statistics/products'
import Funnels from 'containers/statistics/funnels'
import ChatManager from 'containers/managerchat/managerchat.component'
import ChatUser from 'containers/chat/userchat.component'
import ChatMember from 'containers/chat/memberchat.component'
import Usage from 'containers/usage/Usage'
import Period from 'containers/periodlist/periodlist.component'
import Income from 'containers/income/income.component'
import Refund from 'containers/refund/refund.component'
import HistoryUser from 'containers/history/history_user.component'
import HistoryManager from 'containers/history/history_manager.component'
import Coupon from 'containers/coupon/coupon.component'
import PaymentList from 'containers/payments/paymentList.component'
import { ManagerList, AdsCampaignList, AdsCostList, AdsSubjectList } from 'containers/Ads'
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
	return (
		<Layout>
			<Component {...rest} />
		</Layout>
	)
}

const ClassicLayout = (props) => <Classic>{props.children}</Classic>

export default () => {
	return (
		<Routes>
			<Route path="login" element={<Login />} />
			<Route path="" element={<CheckSeqRouter layout={ClassicLayout} />}>
				<Route path="/" element={<Dashboard />} />
				<Route path="dashboard" element={<Dashboard />} />
				<Route path="editinfo" element={<EditInfo />} />
				<Route path="seat" element={<SeatBoard />} />
				<Route path="seatsetting" element={<SeatSetting />} />
				<Route path="sprodsetting" element={<SProdSetting />} />

				<Route path="period" element={<Period />} />
				<Route path="room" element={<StudyRoom />} />
				<Route path="roomsetting" element={<RoomSetting />} />

				<Route path="locker" element={<LockerBoard />} />
				<Route path="lockersetting" element={<LockerSetting />} />
				<Route path="lprodsetting" element={<LProdSetting />} />
				{/* !!!! ----- usage 페이지 변경 및  수정 해야함 -> 오래걸림 ----- !!!!! */}
				<Route path="usage" element={<Usage />} />
				{/* !! ----- Fetching 과정이 이상함 . UX 쓰레기임 ----- !! */}
				<Route path="member" element={<MemberList />} />
				{/* 기능구현 필요함 */}
				<Route path="notice" element={<Notice />} />
				<Route path="kakaotalk" element={<Message />} />
				<Route path="autoNoti" element={<AutoNoti />} />
				{/* 기능구현 필요함 */}
				{/* 좌우 스크롤 안됨 */}
				<Route path="coupon" element={<Coupon />} />
				{/* 좌우 스크롤 안됨 */}
				{/* 날짜선택 안했을 시 이번달 날짜 기준으로 자동 조회 기능 추가 */}
				<Route path="sales" element={<Sales />} />
				<Route path="income" element={<Settlement />} />
				<Route path="analysis/stats/sales" element={<Statistics />} />
				<Route path="analysis/sales" element={<Analysis />} />
				<Route path="analysis/time" element={<Timephased />} />
				<Route path="analysis/products" element={<Products />} />
				<Route path="analysis/funnels" element={<Funnels />} />
				{/* 날짜선택 안했을 시 이번달 날짜 기준으로 자동 조회 기능 추가 */}
				<Route path="chat/manager" element={<ChatManager />} />
				{/* 사용자 피드백 슈퍼관리자에게 다이렉트로 전달되는 페이지 인것 같은데 라우터가 안붙어있음 사용안하는 것일수도 있음 */}
				<Route path="chat/user" element={<ChatUser />} />
				{/* 사용자 피드백 슈퍼관리자에게 다이렉트로 전달되는 페이지 인것 같은데 라우터가 안붙어있음 사용안하는 것일수도 있음 */}
				<Route path="chat/member" element={<ChatMember />} />
				{/* ADMIN ONLY */}
				<Route path="admin" element={<CheckAdminRouter />}>
					<Route path="income/manage" element={<Income />} />
					<Route path="cash" element={<Outlet />}>
						<Route path="history" element={<Cash />} />
						<Route path="refund" element={<Refund />} />
						<Route path="stats/place" element={<CashStats />} />
						<Route path="stats/pg" element={<CashStatsPg />} />
					</Route>
					<Route path="member" element={<AllMemberList />} />
					<Route path="history" element={<Outlet />}>
						<Route path="manager" element={<HistoryManager />} />
						<Route path="payments" element={<PaymentList />} />
						<Route path="user" element={<HistoryUser />} />
					</Route>
					<Route path="board" element={<AdminNotice />}>
						<Route path="" element={<AdminBoard />} />
						<Route path="list" element={<AdminNotice />} />
					</Route>
					<Route path="user" element={<UserList />} />
					<Route path="place" element={<StudyPlace />} />
					<Route path="ads" element={<Outlet />}>
						<Route path="manager" element={<ManagerList />} />
						<Route path="campaign" element={<AdsCampaignList />} />
						<Route path="cost" element={<AdsCostList />} />
						<Route path="subject" element={<AdsSubjectList />} />
					</Route>
				</Route>
				{/* ADMIN ONLY */}
				{/* <Route
					path="history/payments"
					element={<AppRoute component={AsyncPaymentList} props={childProps} layout={activeLayout} />}
				/> */}
				<Route path="franchise" element={<Outlet />}>
					<Route path="place" element={<StudyPlace />} />
					<Route path="member" element={<AllMemberList />} />
					<Route path="income" element={<Income />} />
				</Route>
				<Route path="*" element={<Redirect />} />
			</Route>

			<Route path="errors/404" element={<AppRoute component={AsyncError404} layout={NoLayout} />} />
			<Route path="errors/500" element={<AppRoute component={AsyncError500} layout={NoLayout} />} />
		</Routes>
	)
}
