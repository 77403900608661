import AppBar from '@material-ui/core/AppBar'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import classNames from 'classnames'
import compose from 'recompose/compose'
import ContentFooter from '../components/content-footer/content-footer.component'
import ContentToolbar from '../components/content-toolbar/Content-Toolbar'
// import NotificationSidenav from '../components/notification-sidenav/notification-sidenav.component'
import SideNavLayout from 'layouts/components/sidenav/SideNavLayout'

// Actions

import withNavigation from 'common/withComponent/withNavigation'

import styles from './layout-classic.style'
import scss from './layout-classic.module.scss'
import TitleHeader from './TitleHeader'
import { useEffect, useRef, useState } from 'react'
import { useScroll } from 'framer-motion'
const VIEW_LIST = ['/seat']
const LayoutClassic = ({ width, classes, children, location }) => {
	const isView = VIEW_LIST.includes(location.pathname)
	const isMobile = isWidthDown('sm', width)
	const [isTop, setIsTop] = useState(true)
	const ref = useRef<HTMLDivElement | null>(null)
	const { scrollY } = useScroll({
		container: ref
	})
	useEffect(() => {
		scrollY.onChange(() => {
			if (scrollY.get() >= 20) {
				setIsTop(false)
			} else {
				setIsTop(true)
			}
		})
	}, [scrollY])

	return (
		<div className={`${classNames(scss['layout-classic-wrapper'], classes.wrapper)} flex-col`}>
			<AppBar color="default" position="sticky" style={{ boxShadow: 'none' }}>
				<ContentToolbar />
			</AppBar>
			<main className={`${scss['layout-classic-main']} dark:bg-moaMainDark flex flex-row ${isView && 'overflow-y-scroll'}`}>
				<SideNavLayout />
				<div
					className={`${scss['layout-classic-content-wrapper']}
              overflow-scroll  transition-all scrollbar-hide flex-col max-w-[100vw] `}
					id="body-wrapper"
					ref={ref}
				>
					<TitleHeader isMobile={isMobile} isTop={isTop} />
					<div className={(scss['layout-classic-content'], !isMobile ? 'grow w-full h-fit' : '')}>{children}</div>
					<ContentFooter />
				</div>
			</main>
		</div>
	)
}

export default compose(withWidth(), withStyles(styles, { withTheme: true }))(withNavigation(LayoutClassic))
